.screen .layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  min-height: 100%;
}

.screen .layout .header {
  grid-column: span 6;
  margin-bottom: 0;
}

.screen .layout.half-button .header,
.screen .layout.third-button .header {
  margin-bottom: 20px;
}

.screen .layout.half-button .item,
.screen .layout.half-button-image .item {
  display: grid;
  grid-column: span 3;
  justify-items: center;
  padding: 20px 10px 0px 10px;
  width: 100%;
}

.screen .layout.half-button .item img,
.screen .layout.half-button-image .item img {
  max-width: 125px;
}

.screen .layout.half-button .item .title,
.screen .layout.half-button-image .item .title {
  color: #1f4b99;
  font-size: 0.8rem;
  padding: 6px;
  text-align: center;
}

.screen .layout.half-button-image .item:nth-child(2) {
  grid-column: span 6;
  padding-top: 0;
  position: relative;
}

.screen .layout.half-button-image .item:nth-child(2):after {
  background: #666;
  bottom: 1rem;
  content: '';
  height: 1px;
  position: absolute;
  width: 80%;
}

.screen .layout.half-button-image .item:nth-child(2) img {
  max-width: 100%;
}

.screen .layout.half-button-image .item:nth-child(2) .title {
  font-size: 1.4rem;
  margin: 1rem 1rem 2rem;
}

.screen .layout.third-button .item,
.screen .layout.third-button-image .item {
  display: grid;
  grid-column: span 2;
  justify-items: center;
  padding: 20px 10px 0px 10px;
  width: 100%;
}

.screen .layout.third-button .item img,
.screen .layout.third-button-image .item img {
  max-width: 80px;
}

.screen .layout.third-button .item .title,
.screen .layout.third-button-image .item .title {
  color: #1f4b99;
  font-size: 0.8rem;
  padding: 6px;
  text-align: center;
}

.screen .layout.third-button-image .item:nth-child(2) {
  grid-column: span 6;
  padding-top: 0;
  position: relative;
}

.screen .layout.third-button-image .item:nth-child(2):after {
  background: #666;
  bottom: 1rem;
  content: '';
  height: 1px;
  position: absolute;
  width: 80%;
}

.screen .layout.third-button-image .item:nth-child(2) img {
  max-width: 100%;
}

.screen .layout.third-button-image .item:nth-child(2) .title {
  font-size: 1.4rem;
  margin: 1rem 1rem 2rem;
}

.screen .layout.list .item,
.screen .layout.list-image .item {
  align-items: center;
  background-color: #347bad;
  background-position: 90% 50%;
  background-repeat: no-repeat;
  border-bottom: solid 1px #fff;
  color: #fff;
  display: grid;
  grid-column: span 6;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: left;
  padding: 1px 0;
}

.screen .layout.list .item img,
.screen .layout.list-image .item img {
  max-width: 100px;
}

.screen .layout.list .item .title,
.screen .layout.list-image .item .title {
  align-self: center;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  grid-column: span 2;
  padding-left: 0.5rem;
  text-align: left;
}

.screen .layout.list .item:nth-child(2),
.screen .layout.list-image .item:nth-child(2) {
  border-top: solid 1px #fff;
}

.screen .layout.list-image .item:nth-child(2) {
  background: none;
  color: #347bad;
  grid-template-columns: 1fr;
  justify-items: center;
  padding-top: 0;
  position: relative;
}

.screen .layout.list-image .item:nth-child(2) img {
  max-width: 100%;
}

.screen .layout.list-image .item:nth-child(2) .title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 400;
  grid-column: span 1;
  padding: 1rem 2rem;
  text-align: center;
}

.screen .layout.list-white .item,
.screen .layout.list-white-image .item {
  align-items: center;
  background-color: #fff;
  background-position: 90% 50%;
  background-repeat: no-repeat;
  background-size: 30px;
  border-bottom: solid 1px #ccc;
  color: #347bad;
  display: grid;
  grid-column: span 6;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: left;
  padding: 1px 0;
}

.screen .layout.list-white .item img,
.screen .layout.list-white-image .item img {
  max-width: 100px;
}

.screen .layout.list-white .item .title,
.screen .layout.list-white-image .item .title {
  align-self: center;
  color: #347bad;
  font-size: 1.1rem;
  font-weight: 400;
  grid-column: span 2;
  padding-left: 0.5rem;
  text-align: left;
}

.screen .layout.list-white .item:nth-child(2),
.screen .layout.list-white-image .item:nth-child(2) {
  border-top: solid 1px #ccc;
}

.screen .layout.list-white-image .item:nth-child(2) {
  background: none;
  color: #fff;
  grid-template-columns: 1fr;
  justify-items: center;
  padding-top: 0;
}

.screen .layout.list-white-image .item:nth-child(2) img {
  max-width: 100%;
}

.screen .layout.list-white-image .item:nth-child(2) .title {
  color: #000;
  font-size: 1.4rem;
  grid-column: span 1;
  padding: 1rem 2rem;
  text-align: center;
}

.screen .layout .footer {
  grid-column: span 6;
}
