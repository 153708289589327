@font-face {
  font-family: 'proxima-nova';
  src: url(./assets/fonts/ProximaNova-Light.eot);
  src: url(./assets/fonts/ProximaNova-Light.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/proximanova-light.woff2) format('woff2'),
    url(./assets/fonts/ProximaNova-Light.woff) format('woff'),
    url(./assets/fonts/ProximaNova-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url(./assets/fonts/ProximaNova-Regular.eot);
  src: url(./assets/fonts/ProximaNova-Regular.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/proximanova-reg.woff2) format('woff2'),
    url(./assets/fonts/ProximaNova-Regular.woff) format('woff'),
    url(./assets/fonts/ProximaNova-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url(./assets/fonts/ProximaNova-Semibold.eot);
  src: url(./assets/fonts/ProximaNova-Semibold.eot?#iefix) format('embedded-opentype'),
     url(./assets/fonts/proximanova-sbold.woff2) format('woff2'),
     url(./assets/fonts/ProximaNova-Semibold.woff) format('woff'),
     url(./assets/fonts/ProximaNova-Semibold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url(./assets/fonts/proximanova-bold.woff2) format('woff2'),
    url(./assets/fonts/proximanova-bold.woff) format('woff'),
    url(./assets/fonts/proximanova-bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url(./assets/fonts/proximanova-xbold.woff2) format('woff2'),
    url(./assets/fonts/proximanova-xbold.woff) format('woff'),
    url(./assets/fonts/proximanova-xbold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

body {
  padding: 0;
  margin: 0;
}

#page {
  background-color: #fff;
  color: #232323;
  font-family: 'Avenir', proxima-nova, Verdana, sans-serif;
  font-weight: 300;
  overflow: auto;
}

#page .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

#page a {
  color: #2b72a3;
}

#page #landing-page h1,
#page #landing-page h2,
#page #landing-page h3,
#page #landing-page h4,
#page #landing-page h5,
#page #landing-page h6 {
  color: #15436c;
  font-family: 'Avenir' proxima-nova, Verdana, sans-serif;
  font-weight: 300;
  line-height: 2rem;
}

#page .container {
  margin: 0 auto;
  max-width: 960px;
  padding: 1rem;
}

#page #header-stripe {
  background: #fff;
  width: 100%;
  z-index: 499;
}

#page #header-stripe header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 90px;
}

#page #header-stripe header h1 {
  margin-bottom: 0;
}

#page #header-stripe header img.zap-logo {
  padding: 10px 10px 2px;
  max-width: 75vw;
  height: 75px;
}

#page #header-stripe header nav .hamburger {
  display: none;
}

#page #header-stripe header nav ul#user-menu {
  list-style: none;
  padding-right: 10px;
}

#page #header-stripe header nav ul#user-menu button.login {
  background-color: #fff;
  border: solid 1px #2b72a3;
  border-radius: 0;
  color: #2b72a3;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
}

#page #header-stripe header nav ul#user-menu button.login:hover,
#page #header-stripe header nav ul#user-menu button.login:focus {
  background-color: #2b72a3;
  color: #fff;
}

@media screen and (max-width: 800px) {
  #page #header-stripe header nav .hamburger {
    display: block;
  }

  #page #header-stripe header nav .hamburger button {
    background-color: #fff;
    border: solid 1px #2b72a3;
    cursor: pointer;
    margin-right: 20px;
    padding: 3px;
  }

  #page #header-stripe header nav .hamburger button .icon-bar {
    background-color: #2b72a3;
    border-radius: 1px;
    display: block;
    height: 3px;
    margin: 4px 2px;
    width: 24px;
  }

  #page #header-stripe header nav .hamburger button:focus,
  #page #header-stripe header nav .hamburger button:hover {
    background-color: #2b72a3;
  }

  #page #header-stripe header nav .hamburger button:focus .icon-bar,
  #page #header-stripe header nav .hamburger button:hover .icon-bar {
    background-color: #fff;
  }

  #page #header-stripe header nav ul#user-menu {
    display: none;
  }

  #page #header-stripe header nav ul#user-menu.expanded {
    background: #232323;
    display: block;
    margin-top: 2rem;
    position: absolute;
    padding: 2rem;
    right: 0;
    width: 100%;
    z-index: 10;
  }
}

#page #hero-stripe {
  background-image: url('assets/landing-page-hero.jpg');
  background-position: 0 10%;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 80px;
  min-height: 40vw;
  overflow: auto;
  text-align: center;
}

#page #hero-stripe p.whitebg {
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 14px 5px 10px #fff, -14px -5px 10px #fff, 14px -5px 10px #fff, -14px 5px 10px #fff;
  color: #c9135b;
  font-size: 3rem;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 2rem;
  opacity: 0.9;
  padding: 0.1rem;
  text-align: center;
  width: 50rem;
}

#page #hero-stripe p.whitebg strong {
  font-weight: 300;
}

#page #hero-stripe p.start-button {
  margin-bottom: 0;
}

#page #hero-stripe p.start-button a {
  background: #c9135b;
  background-image: url('assets/arrow.png');
  background-position: 86% 44%;
  background-repeat: no-repeat;
  background-size: auto;
  border: solid 1px #fff;
  box-shadow: 2px 2px 6px #444;
  color: #fff;
  display: inline-block;
  font-size: 0.9rem;
  margin: 1rem;
  overflow: auto;
  padding: 0.6rem 4rem 0.6rem 1.8rem;
  text-transform: uppercase;
}

#page #hero-stripe p.start-button a:hover,
#page #hero-stripe p.start-button a:focus {
  background-color: #fff;
  background-image: url('assets/arrow-hover.png');
  border: solid 1px #c9135b;
  color: #c9135b;
}

@media screen and (max-width: 1000px) {
  #page #hero-stripe {
    background-position: 50% 25%;
    background-size: 150%;
    min-height: 50vw;
  }
}

@media screen and (max-width: 900px) {
  #page #hero-stripe {
    background-position: 50% 15%;
    justify-content: space-between;
    min-height: 60vw;
  }

  #page #hero-stripe p.whitebg {
    line-height: 100%;
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  #page #hero-stripe {
    background-position: 50% 4%;
    min-height: 70vw;
  }

  #page #hero-stripe p.whitebg {
    font-size: 2.6rem;
    padding: 0.1rem;
  }
}

@media screen and (max-width: 600px) {
  #page #hero-stripe {
    background-position: 50% 0;
  }

  #page #hero-stripe p.whitebg {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 500px) {
  #page #hero-stripe p.whitebg {
    font-size: 2rem;
  }
}

#page #mobilizelabs-signature-stripe {
  align-items: center;
  background-color: #15436c;
  background-image: linear-gradient(#2673a6, #15436c);
  color: #fff;
  display: flex;
  justify-content: space-around;
  overflow: auto;
  padding: 1rem 2rem;
  text-align: center;
}

#page #mobilizelabs-signature-stripe .ml-sig-center {
  flex: 1 1 40%;
  order: 2;
}

#page #mobilizelabs-signature-stripe .ml-sig-left {
  background-image: url('assets/ml-icons-left.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 90%;
  flex: 1 1 30%;
  min-height: 200px;
  order: 1;
}

#page #mobilizelabs-signature-stripe .ml-sig-right {
  background-image: url('assets/ml-icons-right.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 90%;
  flex: 1 1 30%;
  min-height: 200px;
  order: 3;
}

@media screen and (max-width: 1200px) {
  #page #mobilizelabs-signature-stripe .ml-sig-left {
    background-size: 100% auto;
  }

  #page #mobilizelabs-signature-stripe .ml-sig-right {
    background-size: 100% auto;
  }
}

@media screen and (max-width: 900px) {
  #page #mobilizelabs-signature-stripe .ml-sig-center {
    flex: 1 1 50%;
  }

  #page #mobilizelabs-signature-stripe .ml-sig-left {
    background-size: 100% auto;
    flex: 1 1 24%;
  }

  #page #mobilizelabs-signature-stripe .ml-sig-right {
    background-size: 100% auto;
    flex: 1 1 24%;
  }
}

@media screen and (max-width: 720px) {
  #page #mobilizelabs-signature-stripe .ml-sig-center {
    flex: 1 1 100%;
  }

  #page #mobilizelabs-signature-stripe .ml-sig-left {
    background-size: 100% auto;
    display: none;
    flex: 1 1 0%;
  }

  #page #mobilizelabs-signature-stripe .ml-sig-right {
    background-size: 100% auto;
    display: none;
    flex: 1 1 0%;
  }
}

#page #personalized-experience-stripe {
  background-color: #fff9bf;
  background-image: linear-gradient(#fff9bf, #ffee40, #febb36);
  overflow: auto;
  padding: 2rem;
  text-align: center;
}

#page #personalized-experience-stripe p,
#page #personalized-experience-stripe li {
  color: #15436c;
}

#page #personalized-experience-stripe .pex {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

#page #personalized-experience-stripe .pex ul {
  list-style: none;
  padding-left: 0;
}

#page #personalized-experience-stripe .pex ul li {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  justify-content: flex-end;
}

#page #personalized-experience-stripe .pex .pex-center {
  flex: 1 1 30%;
  order: 2;
}

#page #personalized-experience-stripe .pex .pex-center .phone-shell {
  margin: auto;
  min-height: 620px;
  position: relative;
  width: 19.6rem;
}

#page #personalized-experience-stripe .pex .pex-center .phone-shell .phone-shell-inner {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#page #personalized-experience-stripe .pex .pex-center .phone-shell .phone-shell-inner .phone-shell-bg {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

#page #personalized-experience-stripe .pex .pex-center .phone-shell .phone-shell-inner .phone-shell-bg img {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}

#page #personalized-experience-stripe .pex .pex-center .phone-shell .phone-screen {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 18px;
  width: 100%;
}

#page #personalized-experience-stripe .pex .pex-center .phone-shell .phone-screen .phone-screen-inner {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

#page #personalized-experience-stripe .pex .pex-center .phone-shell .phone-screen .phone-screen-inner img {
  display: none;
}

#page #personalized-experience-stripe .pex .pex-center .phone-shell .phone-screen .phone-screen-inner video {
  border-radius: 33px;
}

#page #personalized-experience-stripe .pex .pex-left {
  flex: 1 1 30%;
  order: 1;
}

#page #personalized-experience-stripe .pex .pex-left #config-dash {
  background-image: url('assets/configurable-dash.png');
}

#page #personalized-experience-stripe .pex .pex-left #rich-temp {
  background-image: url('assets/rich-templates.png');
}

#page #personalized-experience-stripe .pex .pex-left #dyn-cm {
  background-image: url('assets/dynamic-cm.png');
}

#page #personalized-experience-stripe .pex .pex-right {
  flex: 1 1 30%;
  order: 3;
}

#page #personalized-experience-stripe .pex .pex-right #map-int {
  background-image: url('assets/map-integration.png');
}

#page #personalized-experience-stripe .pex .pex-right #starter-kit {
  background-image: url('assets/starter-kit.png');
}

#page #personalized-experience-stripe .pex .pex-right #easy-clone {
  background-image: url('assets/clone.png');
}

@media screen and (max-width: 800px) {
  #page #personalized-experience-stripe .pex {
    flex-direction: column;
  }

  #page #personalized-experience-stripe .pex .pex-center {
    order: 1;
  }

  #page #personalized-experience-stripe .pex .pex-left {
    order: 2;
  }

  #page #personalized-experience-stripe .pex .pex-right {
    order: 3;
  }
}

#page #extend-messaging-stripe {
  background-color: #6ff;
  background-image: linear-gradient(#6ff, #4bb1f9 40%, #347bad 50%, #c9135b);
  margin: 0;
  overflow: auto;
  padding: 2rem 2rem 1rem 2rem;
  text-align: center;
}

#page #extend-messaging-stripe #extend {
  padding-bottom: 2rem;
}

#page #extend-messaging-stripe #extend p,
#page #extend-messaging-stripe #extend li {
  color: #15436c;
}

#page #extend-messaging-stripe #extend ul {
  align-items: center;
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding-left: 0;
}

#page #extend-messaging-stripe #extend ul li {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  min-height: 200px;
  display: flex;
  flex: 1 1 20%;
  flex-direction: column;
  font-size: 1.2rem;
  justify-content: flex-end;
}

#page #extend-messaging-stripe #extend ul li#notifications {
  background-image: url('assets/notifications.png');
}

#page #extend-messaging-stripe #extend ul li#notifications span {
  font-size: 1rem;
}

#page #extend-messaging-stripe #extend ul li#timelines {
  background-image: url('assets/timelines.png');
}

#page #extend-messaging-stripe #extend ul li#embed-videos {
  background-image: url('assets/videos.png');
}

#page #extend-messaging-stripe #extend ul li#cal-int {
  background-image: url('assets/calendar.png');
}

#page #extend-messaging-stripe #extend ul li#vr {
  background-image: url('assets/vr.png');
}

@media screen and (max-width: 800px) {
  #page #extend-messaging-stripe #extend ul {
    flex-wrap: wrap;
  }

  #page #extend-messaging-stripe #extend ul li {
    flex: 0 0 50%;
    min-height: 150px;
  }
}

#page #extend-messaging-stripe #mlmessaging {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: space-around;
  overflow: auto;
  padding: 1rem 2rem;
  text-align: center;
}

#page #extend-messaging-stripe #mlmessaging #mlmessaging-left {
  margin: 0 2rem;
}

#page #extend-messaging-stripe #mlmessaging #mlmessaging-left h2 {
  color: #fff;
}

#page #extend-messaging-stripe #mlmessaging #mlmessaging-left p {
  font-size: 1.1rem;
}

#page #extend-messaging-stripe #mlmessaging #mlmessaging-left img {
  padding-top: 1rem;
}

#page #extend-messaging-stripe #mlmessaging #mlmessaging-right {
  display: flex;
  margin-top: 2rem;
}

#page #extend-messaging-stripe #mlmessaging #mlmessaging-right #usersettings {
  margin-right: 2rem;
}

#page #extend-messaging-stripe #mlmessaging #mlmessaging-right #usersettings img {
  max-height: 312px;
}

@media screen and (max-width: 800px) {
  #page #extend-messaging-stripe #mlmessaging {
    flex-direction: column;
  }

  #page #extend-messaging-stripe #mlmessaging #mlmessaging-left {
    margin: 0;
  }

  #page #extend-messaging-stripe #mlmessaging #mlmessaging-right img {
    max-width: 100%;
  }
}

#page #footer-stripe {
  background-color: #15436c;
  background-image: linear-gradient(#2673a6, #15436c);
  color: #fff;
  overflow: auto;
  text-align: center;
}

#page #footer-stripe .footer-top {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

#page #footer-stripe .footer-top .left {
  background-image: url('assets/letsgetstarted.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
  min-height: 450px;
  max-width: 485px;
  flex: 1 1 auto;
}

#page #footer-stripe .footer-top .right {
  flex: 1 1 auto;
  font-size: 1.2rem;
  max-width: 485px;
}

#page #footer-stripe .footer-top .right h2 {
  color: #fff;
  font-size: 2.6rem;
}

#page #footer-stripe .footer-top .right span.contact {
  text-transform: uppercase;
}

#page #footer-stripe .footer-top .right a.contact-email {
  color: #fff;
  text-decoration: underline;
}

#page #footer-stripe .footer-top .right a.contact-email:hover {
  text-decoration: none;
}

#page #footer-stripe .footer-top .right a.contact-email:focus {
  outline: rgba(255, 255, 255, 0.5) solid 8px;
  outline-offset: 4px;
  text-decoration: none;
}

#page #footer-stripe .footer-top .right a.demo-btn {
  background: #c9135b;
  border: solid 1px #fff;
  color: #fff;
  display: inline-block;
  font-size: 0.9rem;
  margin: 1rem;
  overflow: auto;
  padding: 0.6rem 1.8rem;
  text-transform: uppercase;
}

#page #footer-stripe .footer-top .right a.demo-btn:hover,
#page #footer-stripe .footer-top .right a.demo-btn:focus {
  background-color: #fff;
  color: #c9135b;
}

@media screen and (max-width: 1100px) {
  #page #footer-stripe .footer-top .left {
    background-size: contain;
    margin-left: 40px;
  }

  #page #footer-stripe .footer-top .right {
    max-width: 50%;
  }
}

@media screen and (max-width: 800px) {
  #page #footer-stripe .footer-top {
    flex-direction: column;
  }

  #page #footer-stripe .footer-top .left {
    background-position: 20% 0px;
    background-size: 90%;
    max-width: 485px;
    max-height: 90vw;
    order: 2;
    width: 90%;
  }

  #page #footer-stripe .footer-top .right {
    max-width: 100%;
    order: 1;
  }
}

@media screen and (max-width: 600px) {
  #page #footer-stripe .footer-top .left {
    background-position: 4% 0px;
    background-size: contain;
    margin-bottom: 20px;
    margin-left: 10px;
    max-height: 75vw;
    max-width: 90%;
    min-height: 75vw;
  }
}

@media screen and (max-width: 450px) {
  #page #footer-stripe .footer-top .left {
    height: 60vw;
    margin-bottom: 40px;
    max-height: 60vw;
    min-height: 60vw;
  }
}

#page #footer-stripe .footer-bottom {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: -50px;
}

#page #footer-stripe .footer-bottom .left a:focus {
  outline: rgba(255, 255, 255, 0.5) solid 8px;
  outline-offset: 4px;
}

#page #footer-stripe .footer-bottom .left .oarc-logo {
  padding: 0.5rem 0.5rem 0 0.5rem;
}

#page #footer-stripe .footer-bottom .right img.ucla-made {
  margin-bottom: -20px;
  margin-right: 20px;
  margin-top: -150px;
}

@media screen and (max-width: 500px) {
  #page #footer-stripe .footer-bottom {
    max-width: 100%;
  }

  #page #footer-stripe .footer-bottom .left {
    max-width: 75%;
  }

  #page #footer-stripe .footer-bottom .left .oarc-logo {
    max-width: 100%;
  }

  #page #footer-stripe .footer-bottom .right {
    max-width: 24%;
  }

  #page #footer-stripe .footer-bottom .right img.ucla-made {
    margin-bottom: -30px;
    margin-right: 4px;
    max-width: 90%;
  }
}

#page #footer-stripe .copyright {
  padding: 0 0.5rem 0.5rem 0.5rem;
  text-align: left;
}
